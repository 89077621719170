.portfolio__landing {
    display: flex;
    flex-direction: row;
    max-height: 937px;
}

.portfolio__landing-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 62px;
}

.portfolio__landing-content h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;
}

.portfolio__landing-image {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: right;
}


.portfolio__landing-image-bg{
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 100;
}

.portfolio__landing-image-fg {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    right: 0;
}

.portfolio__landing-image-profile {
    position: relative;
    max-height: 800px;
}

.logo-landing {
    position: absolute;
    max-width: 56px;
    max-height: auto;
    left: 62px;
    top: 62px;
}

@media screen and (max-width: 1050px) {
    .portfolio__landing {
        flex-direction: column;
    }

    .portfolio__landing-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {

    .portfolio__landing-image {
        display: none;
    }
}

@media screen and (max-width: 490px) {
    .portfolio__landing h1 {
        font-size: 60px;
        line-height: 84px;
    }

    .portfolio__landing-image-bg {
        display: none;
    }

    .portfolio__landing-image-fg {
        display: none;
    }

    .logo-landing {
        display: none;
    }
}
