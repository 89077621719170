.portfolio__boxmotion {
    display: flex;
    flex-direction: row;
    height: 937px;
    justify-content: center;
    align-items: center; 
}

.portfolio__boxmotion-content {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 62px;
}

.portfolio__boxmotion-content-implementation {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 62px;
}

.portfolio__boxmotion-content h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;
    text-align: left;
}

.portfolio__boxmotion-content h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
    margin-top: 84px;
}

.portfolio__boxmotion-content h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
}

.portfolio__boxmotion-content-implementation h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
}

.portfolio__boxmotion-image {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
}

.portfolio__boxmotion-image-product {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 265px;
    height: 536px;
    margin-right: 50px;
}

.portfolio__boxmotion-image-bg{
    max-width: 100%;
    max-height: 100%;
}

.portfolio__boxmotion-image-fg {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
}

.portfolio__boxmotion-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 34px;
    line-height: 57px;
    text-align: justify;

    margin-left: 152px;
    padding-bottom: 30px;
}

.requirements_boxmotion {
    margin-top: 91px;
}

.portfolio__boxmotion-content-implementation p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 28.13px;
    text-align: justify;
    max-width: 524px;
    max-height: 475px;
}

.spacer_boxmotion {
    margin-left: 152px;
}

.distance_boxmotion {
    margin-top: -194px;
}

.headline_implementation_boxmotion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    margin-left: 62px;
}

.headline_implementation_boxmotion h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
    margin-bottom: -150px;
}

@media screen and (max-width: 1050px) {
    .portfolio__boxmotion {
        flex-direction: column;
    }

    .portfolio__boxmotion-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .portfolio__boxmotion h1 {
        font-size: 48px;
        line-height: 60px;
    }
}

@media screen and (max-width: 490px) {
    .portfolio__boxmotion h1 {
        font-size: 36px;
        line-height: 48px;
    }
}