@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;

  --gradient-text1: linear-gradient(233.73deg, #DF8C41 14.24%, #D753A2 108.07%);
  --gradient-text2: linear-gradient(86.8deg, #C550BA 40.49%, #8F38E9 71.43%);
  --gradient-text3: linear-gradient(97.73deg, #B72CDF 29.72%, #EDBA1E 77.51%);
  --gradient-text4: linear-gradient(84.74deg, #0795CC 20.89%, #7EEBFD 72.58%);
  --gradient-text5: linear-gradient(277.4deg, #FDD191 34.41%, #FA9D65 76.07%);
  --gradient-text6: linear-gradient(87.49deg, #A3C2D1 37.56%, #DB6371 102.88%);
  --gradient-text7: linear-gradient(87.12deg, #7891BB 27.47%, #B75655 67.2%);
  --gradient-text8: linear-gradient(253.46deg, #E8C6D7 26.05%, #C7D99B 73.11%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #ffffff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}