*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__text__landing {
    background:var(--gradient-text1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__eventeers {
    background:var(--gradient-text2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__boxmotion {
    background:var(--gradient-text3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__mobility {
    background:var(--gradient-text4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__light {
    background:var(--gradient-text5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__motivation {
    background:var(--gradient-text6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__food {
    background:var(--gradient-text7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__automotive {
    background:var(--gradient-text8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

.gradient__text__contact {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .mobile_disclaimer {
    display: none;
  }

  @media screen and (max-width: 700px) {
      .app__content {
        display: none;
      }

      .mobile_disclaimer {
        display: block;
        padding: 100px;
      }

      .mobile_disclaimer h1 {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
      }
  }

  @media screen and (max-width: 490px) {
    .app__content {
      display: none;
    }
    .mobile_disclaimer {
      display: block;
      padding: 50px;
    }

    .mobile_disclaimer h1 {
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
    }
}

.scale-up-right {
	-webkit-animation: scale-up-right 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-right 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-right {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @keyframes scale-up-right {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  
.fade-out {
  -webkit-animation: fade-out 0.8s ease-out both;
          animation: fade-out 0.8s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* .fade-in {
	-webkit-animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.animate {
  opacity: 0%;
  transition: opacity 600ms ease-in;
}

.animate.fade {
  opacity: 100%;
}