.portfolio__aboutme {
    display: flex;
    flex-direction: row;
    height: 937px;
}

.portfolio__aboutme-content {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 62px;
}

.portfolio__aboutme-content button {
    background-image: var(--gradient-text1);
    box-shadow: none;
    border: none;
    border-radius: 50px;
    padding: 15px;
    color: white;
    
    width: 270px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    margin-left: 152px;
}

.textAndButton {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.portfolio__aboutme-content-overview {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 62px;
}

.portfolio__aboutme-content-overview h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;
}

.portfolio__aboutme-content-overview h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.portfolio__aboutme-content h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;
}

.portfolio__aboutme-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: justify;

    margin-top: 152px;
    margin-left: 152px;
    margin-bottom: 70px;

    max-width: 569px;
    max-height: 396px;

    flex-direction: row;
}