.portfolio__eventeers {
    display: flex;
    flex-direction: row;
    height: 937px;
    justify-content: center;
    align-items: center; 
}

.portfolio__eventeers-content {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 62px;
}

.portfolio__eventeers-content-implementation {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 62px;
}

.portfolio__eventeers-content h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 96px;
    line-height: 112px;
    text-align: right;
}

.portfolio__eventeers-content h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
    margin-top: 84px;
}

.headline_implementation_eventeers {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: right;
    margin-right: 62px;
}

.headline_implementation_eventeers h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
    margin-bottom: -150px;
}

.portfolio__eventeers-content h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
}

.portfolio__eventeers-content-implementation h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
}

.portfolio__eventeers-image {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
}

.portfolio__eventeers-image-product {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.portfolio__eventeers-image-bg{
    max-width: 100%;
    max-height: 100%;
}

.portfolio__eventeers-image-fg {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
}

.portfolio__eventeers-image-product {
    position: relative;
    width: 265px;
    height: 536px;
    margin-left: 50px;
}

.portfolio__eventeers-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 34px;
    line-height: 57px;
    text-align: justify;

    margin-right: 152px;
    padding-bottom: 30px;   
}

.requirements_eventeers {
    margin-top: 91px;
}

.portfolio__eventeers-content-implementation p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
    max-width: 524px;
    max-height: 475px;
}

.spacer_eventeers {
    padding-right: 152px;
}

@media screen and (max-width: 1050px) {
    .portfolio__eventeers {
        flex-direction: column;
    }

    .portfolio__eventeers-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .portfolio__eventeers h1 {
        font-size: 48px;
        line-height: 60px;
    }
}

@media screen and (max-width: 490px) {
    .portfolio__eventeers h1 {
        font-size: 36px;
        line-height: 48px;
    }
}