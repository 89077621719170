.portfolio__contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    background-image: var(--gradient-text1);
    padding: 100px;
    margin: 120px;
}

.portfolio__contact-content {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
}

.portfolio__contact-content h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 72px;
    line-height: 84px;
}

.portfolio__contact-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 34px;
    line-height: 57px;
    text-align: justify;
    color: white;

    margin-left: 152px;
    padding-bottom: 30px;
}

.requirements_contact {
    margin-top: 62px;
}

.logo-content {
    max-height: 500px;
    fill: white;
}

@media screen and (max-width: 1050px) {
    .portfolio__contact {
        flex-direction: column;
    }

    .portfolio__contact-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .portfolio__contact h1 {
        font-size: 48px;
        line-height: 60px;
    }
}

@media screen and (max-width: 490px) {
    .portfolio__contact h1 {
        font-size: 36px;
        line-height: 48px;
    }
}